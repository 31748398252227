<template>
    <div v-if="$store.state.accountType === 'premium'">
        <Header></Header>
        <search
            ref="search"
            search-type="ecosystems"
            @invite="processAction"
            @send-message="openChat"
        >
        </search>

        <invite-or-connect-ecosystem
            ref="inviteOrConnectEcosystem"
            @inviteSent="$refs.search.getList()"
            @connectionEstablished="$refs.search.getList()"
        ></invite-or-connect-ecosystem>

        <StartChatPopup
            ref="startChat"
            contactsType="ecosystem"
        />

    </div>
</template>

<script>
import Search from "@/views/search/Search"
import Header from "@/components/ecosystems/Header";

export default {
    components: {
        Search,
        Header,
        InviteOrConnectEcosystem: () => import("@/components/ecosystem/InviteOrConnectEcosystem"),
        StartChatPopup: () => import("@/views/messages/StartChatPopup"),
    },
    mounted() {
        this.$store.watch(state => {
            if (typeof state.accountType != 'undefined' && state.accountType != 'premium') {
                this.$store.commit('setPageNotFound', true)
            }
        })
    },
    methods: {
        processAction(id) {
            this.$refs.inviteOrConnectEcosystem.process(id)
        },

        openChat(id, params = {}) {
            this.$refs.startChat.open(id, params?.receiver)
        }
    }
}
</script>
