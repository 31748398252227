<template>
    <div>
        <b-modal title="Save Search" id="save-search-popup" modal-class="modal-w-lg">

            <form class="form form-modal" @submit.prevent="save">
                <div class="form__item mb-4">
                    <label class="form__label label-primary">Search Name</label>
                    <input v-model="form.name" class="form-control form-control--mid">
                </div>

                <div class="row mb-2">
                    <div class="form__item col">
                        <div class="card card--info h-100 m-0">
                            <label class="form__label form__label--privacy">Privacy</label>

                            <div class="input-radio mb-3">
                                <input id="pr1" type="radio" v-model="form.type" value="personal">
                                <label for="pr1">Only me</label>
                            </div>
                            <div class="input-radio">
                                <input id="pr2" type="radio" v-model="form.type" value="company">
                                <label for="pr2">Shared with company</label>
                            </div>
                        </div>
                    </div>

                    <div v-if="$store.getters.isEnterprise && globalSavedSearchNotificationsEnabled !== null" class="form__item col">
                        <div class="card card--info h-100 m-0">
                            <label class="form__label form__label--notifications">Notifications</label>

                            <div v-if="globalSavedSearchNotificationsEnabled">
                                Your notification settings are set to global
                                <a href="/settings/notifications?tab=searches" target="_blank">Edit notification settings</a>
                            </div>

                            <div v-else class="input-checkbox">
                                <input id="enable-notifications" type="checkbox" v-model="form.notification_enabled">
                                <label for="enable-notifications">Enable notifications</label>

                                <i class="form__label__hint">You will receive an email when new {{ searchType }} match your search</i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form__item" v-if="searchType === searchTypes.STARTUPS && $store.getters.isEnterprise">
                    <div class="auto-scout">
                        <h5 class="auto-scout__title">Auto-Scout</h5>
                    
                        <p class="modal-text">Auto-Scout automatically adds new matches to the trackers of your choice.</p>
                    </div>
                    <div>
                        <div class="input-checkbox">
                            <input id="auto-scouting" type="checkbox" v-model="form.auto_scout">
                            <label for="auto-scouting">Enable Auto-Scout</label>
                        </div>
                    </div>
                </div>

                <div class="form__item row">

                    <div class="col-md-8">
                        <template v-if="form.auto_scout">
                            <label class="form__label">Add New Matches To Tracker:</label>
                            <multiselect
                                v-model="trackers"
                                :options="trackersList"
                                :multiple="true"
                                select-label=""
                                deselect-label=""
                                placeholder="Type to search your trackers"
                                label="name"
                                track-by="name"
                                class="multiselect-mid selectbox selectbox-mid mb-3"
                            >
                            </multiselect>
                        </template>
                    </div>

                    <div class="col-md-4" v-if="form.auto_scout && stagesList.length">
                        <label class="form__label">Add To Stage:</label>
                        <multiselect
                            v-model="stage"
                            :options="stagesList"
                            select-label=""
                            deselect-label=""
                            label="name"
                            track-by="name"
                            class="multiselect-mid selectbox selectbox-mid mb-3"
                        >
                        </multiselect>
                    </div>
                </div>
            </form>

            <template #modal-footer>
                <b-button :disabled="!canSave" variant="primary" size="lg" @click="save">Save</b-button>
            </template>
        </b-modal>

        <b-modal title="Manage Saved Searches" id="manage-saved-searches-popup" modal-class="modal-w-middle" ok-only ok-variant="secondary" ok-title="Close" hide-footer>

            <div v-for="(group, groupName) in list" :key="groupName">
                <h5 class="heading5 heading5--secondary">{{groupName}}</h5>

                <ul class="manage-list">
                    <li v-for="(item, index) in group" :key="index">
                        <p class="manage-list__title">{{list[groupName][index].name}}</p>

                        <a class="manage-list__status" v-if="searchType === searchTypes.STARTUPS && $store.getters.isEnterprise" :class="{'manage-list__active': item.auto_scout}" @click="setAutoScout(item)">Auto-Scout</a>
                        
                        <div class="manage-list__controls">
                            <a @click="openSave(item.id)" class="link-edit">Edit</a>

                            <a
                                v-if="agencyClients.length"
                                @click="openDuplicateModal(item.id)" class="link-copy"
                            >
                                Copy to Client
                            </a>

                            <a @click="deleteSearch(item.id)" class="link-remove">Delete</a>
                        </div>
                    </li>
                </ul>
            </div>
        </b-modal>

        <b-modal id="save-search-popup-trackers" title="Before You Enable Auto-Scout...">
            <p>Startup questions and auto-scout can't be turned on at the same time. Disable startup questions by editing this tracker in order to
                turn on Auto-Scout</p>

            <template #modal-footer>
                <b-button variant="primary" size="lg" @click="$bvModal.hide('save-search-popup-trackers')">Okay</b-button>
            </template>
        </b-modal>

        <b-modal v-model="showDuplicateModal" title="Duplicate Saved Search">
            <multiselect
                class="multiselect-mid"
                v-model="client"
                :options="agencyClients"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
            >
            </multiselect>

            <template #modal-footer>
                <PrimaryButton
                    :disabled="!client"
                    caption="Duplicate Saved Search"
                    loading-caption="Creating Duplicate..."
                    :loading="creatingDuplicate"
                    @click="duplicateSearch"
                />
            </template>
        </b-modal>

    </div>
</template>

<script>

import savedSearchesService from '@/services/saved-searches.service'
import trackers from '@/services/trackers.service'
import multiselect from "vue-multiselect";
import searchTypes from "@/constants/searchTypes";
import {mapActions, mapGetters} from "vuex";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import Vue from "vue";

export default {
    props: {
        searchData: Object,
        savedSearchesList: Array,
        searchType: String,
    },

    components: {
        PrimaryButton,
        multiselect
    },

    data() {
        return {
            id: 0,

            form: {
                name,
                type: 'personal',
                trackers_ids: [],
                stage_id: null,
                auto_scout: false,
                notification_enabled: false,
            },

            notificationEnabled: false,
            trackers: [],
            stage: null,

            trackersList: [],
            stagesList: [],

            list: {},
            saving: false,

            notWatchTrackers: false,
            globalSavedSearchNotificationsEnabled: null,
            showDuplicateModal: false,
            client: null,
            currentSearchId: null,
            creatingDuplicate: false,
        }
    },

    watch: {
        savedSearchesList() {
            let list = {}

            this.savedSearchesList.forEach(group => {
                list[group.group] = []

                group.items.forEach((item) => {
                    list[group.group].push({
                        id: item.id,
                        name: item.name,
                        newName: item.name,
                        auto_scout: item.auto_scout,
                        has_tackers: item.has_tackers,
                        editMode: false,
                    })
                })
            })

            this.list = list
        },

        'form.auto_scout'() {
            if (!this.form.auto_scout) {
                this.trackers = []
            }
        },

        trackers() {
            if (!this.notWatchTrackers) {
                this.form.trackers_ids = this.trackers.map(item => item.id);

                for (let i in this.trackers) {
                    if (this.trackers[i].has_questions) {
                        this.trackers.splice(i, 1)

                        this.$bvModal.show('save-search-popup-trackers')
                    }
                }

                this.getStages()
            }
        },
    },

    computed: {
        ...mapGetters(['agencyClients']),

        searchTypes() {
            return searchTypes
        },

        canSave() {
            return this.form.name.length && !this.saving;
        }
    },

    mounted() {
        if (this.$store.getters.isEnterprise) {
            this.getTrackers()
        }

        savedSearchesService.globalSavedSearchNotificationsEnabled().then(response => {
            this.globalSavedSearchNotificationsEnabled = response.data
        })
    },

    methods: {
        ...mapActions(['fetchAgencyClients']),

        openSave(id = 0) {
            this.id = 0;
            this.form.name = '';
            this.form.type = 'personal';
            this.form.trackers_ids = [];
            this.form.stage_id = null;
            this.form.auto_scout = false;

            this.stage = null;

            if (id) {
                this.id = id
                savedSearchesService.getEdit(id).then(response => {
                    this.form = response.data;

                    this.notWatchTrackers = true
                    this.trackers = this.trackersList.filter(item => response.data.trackers_ids.includes(item.id));

                    this.$nextTick(() => {
                        this.notWatchTrackers = false
                    })

                    this.getStages()

                    this.$bvModal.show('save-search-popup')
                })
            } else {
                this.$bvModal.show('save-search-popup')
            }
        },

        save() {
            if (this.canSave) {
                this.saving = true;
                this.form.stage_id = this.stage ? this.stage.id : null;

                let data = {
                    ...this.form,
                    search_type: this.searchType,
                    search_data: this.searchData,
                }

                savedSearchesService.save(this.id, data).then(() => {
                    this.$bvModal.hide('save-search-popup')
                    this.$emit('saved-search-changed')
                }).finally(() => {
                    this.saving = false;
                })
            }
        },

        setAutoScout(item) {
            savedSearchesService.setAutoScout(item.id, {auto_scout: !item.auto_scout}).then(() => {
                this.$emit('saved-search-changed')

                if (!item.auto_scout && !item.has_tackers) {
                    this.openSave(item.id)
                }
            })
        },

        getTrackers() {
            trackers.getListForSavedSearch().then(response => {
                this.trackersList = response.data
            })
        },

        getStages() {
            trackers.getTrackersStages(this.form.trackers_ids).then(response => {
                this.stagesList = response.data

                if (!this.stagesList.length) {
                    this.stage = null
                } else if (this.form.stage_id) {
                    this.stage = this.stagesList.find(stage => stage.id === this.form.stage_id)
                }
            })
        },

        openManage() {
            this.$bvModal.show('manage-saved-searches-popup');

            if (this.$store.state.user.has_agency_clients) {
                this.fetchAgencyClients();
            }
        },

        updateQuery(id) {
            savedSearchesService.updateQuery(id, this.searchData).then(() => {
                this.$emit('saved-search-changed')
            })
        },

        deleteSearch(id) {
            if (confirm("Delete Search?")) {
                savedSearchesService.deleteSearch(id).then(() => {
                    this.$emit('saved-search-changed')
                })
            }
        },

        openDuplicateModal(searchId) {
            this.currentSearchId = searchId;
            this.client = null;
            this.showDuplicateModal = true;
        },

        async duplicateSearch() {
            this.creatingDuplicate = true;
            await savedSearchesService.duplicate(this.currentSearchId, this.client.id);
            this.creatingDuplicate = false;
            this.showDuplicateModal = false;

            Vue.notify({
                group: 'app',
                type: 'success',
                duration: 5000,
                text: "Duplicate created",
            });

        },
    }
}
</script>
