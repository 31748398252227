var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-grow-1 bg-white"},[_vm._m(0),_c('div',{staticClass:"main-inner flex-grow-1"},[_c('div',{staticClass:"row"},_vm._l((_setup.clients),function(client){return _c('div',{key:client.id,staticClass:"col-md-12 col-lg-6"},[_c('div',{staticClass:"card card--agency"},[_c('div',{staticClass:"card__content"},[_c(_setup.Avatar,{staticClass:"image-block image-block--lg",attrs:{"src":client.logo,"username":client.name,"customStyle":{
                                backgroundColor: '#fff',
                                borderRadius: '6px',
                                boxShadow: '1px 1px 5px 0 rgba(0, 0, 0, 0.15)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                display: 'flex',
                                overflow: 'hidden',
                                marginRight: '31px',
                                marginBottom: '0',
                                flex: '0 0 110px',
                                width: '110px',
                                height: '110px'
                            }}}),_c('div',[_c('h2',{staticClass:"heading2 heading2--secondary"},[_vm._v(_vm._s(client.name))])]),_c('a',{staticClass:"btn btn-primary btn-lg ml-auto",on:{"click":function($event){return _setup.login(client.id)}}},[_vm._v("Log Into Account")])],1),_c('footer',{staticClass:"card__footer"},[_c('span',{staticClass:"card--agency__user"},[_vm._v(_vm._s(client.users_count)+" "+_vm._s(_vm.stringHelper.getNoun('user', client.users_count)))]),(client.last_activity === 'online')?_c('span',{staticClass:"card--agency__activity"},[_vm._v("Online")]):_c('span',{staticClass:"card--agency__activity"},[_vm._v("Last seen "+_vm._s(client.last_activity))]),_c('span',{staticClass:"card--agency__trackers"},[_vm._v(_vm._s(client.trackers_count)+" "+_vm._s(_vm.stringHelper.getNoun('tracker', client.trackers_count)))])])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tracker-section tracker-section--inline"},[_c('h1',{staticClass:"heading1 mb-3"},[_vm._v("My Clients")])])
}]

export { render, staticRenderFns }