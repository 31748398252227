export const constants = {
    SEARCH_TYPE_STARTUPS: 'startups',
    SEARCH_TYPE_ECOSYSTEMS: 'ecosystems',
    SEARCH_TYPE_ENTERPRISES: 'enterprises',
    MODAL_NEED_UPGRADE: 'needUpgrade',
    DISPLAY_TYPE_LIST: 'list',
    DISPLAY_TYPE_RADAR: 'radar',
    CUSTOM_VIEW_MY_STARTUPS: 'my-startups',
}

export const columnsComponentsNames = {
    'name': 'SearchCompanyNameColumn',
    'location': 'SearchCompanyLocationColumn',
    'ecosystems': 'RawClampColumn',
    'irl_score': 'IRLScore',
    'tags': 'TagsColumn',
}
