import {computed, reactive, ref} from "vue";
import {useStore} from "vue2-helpers/vuex";
import investorsService from "@/services/investors.service";
import filterConstants from "@/constants/searchFilterOptions";
import locationsService from "@/services/locations.service";
import clientsService from "@/services/clients.service";
import ecosystemsService from "@/services/ecosystems.service";
import trackersService from "@/services/trackers.service";

export default function() {
    const store = useStore();

    const topInvestors = ref([]);
    const topLocations = ref([]);
    const topClients = ref([]);
    const topEcosystems = ref([]);

    const filtersOptions = reactive({
        categories: computed(() => convertArray(store.state.categories)),
        fundingSeries: filterConstants.fundingSeries,
        fundingRange: filterConstants.fundingRange,
        locations: [],
        investors: [],
        tags: computed(() => convertArray(store.getters.tagsList)),
        clients: [],
        ecosystems: [],
        trackers: [],
        revenueRange: filterConstants.revenueRange,
        employeeCount: filterConstants.employeeCount,
        ecosystemTypes: filterConstants.ecosystemTypes
    });

    async function getInvestorsList(search) {
        if (search.length) {
            const {data} = await investorsService.getListForSearch(search);
            filtersOptions.investors = convertArray(data);
        } else {
            filtersOptions.investors = topInvestors.value;
        }
    }

    async function getTopInvestorsList() {
        const {data} = await investorsService.getTopListForSearch();
        topInvestors.value = convertArray(data);
        filtersOptions.investors = topInvestors.value;
    }

    async function getLocationsList(query) {
        if (query.length) {
            const {data} = await locationsService.getFilteredStartupsLocations(query);
            filtersOptions.locations = data;
        } else {
            filtersOptions.locations = topLocations.value;
        }
    }

    async function getTopLocationsList() {
        const {data} = await locationsService.getTopStartupsLocations();
        topLocations.value = data;
        filtersOptions.locations = topLocations.value;
    }

    function getTagsList() {
        if (!store.getters.tagsLoaded) {
            store.dispatch('fetchTags');
        }
    }

    async function getTopClientsList() {
        const {data} = await clientsService.getTopListForSearch();
        topClients.value = convertArray(data);
        filtersOptions.clients = topClients.value;
    }

    async function getClientsList(query) {
        if (query.length) {
            const {data} = await clientsService.getListForSearch(query);
            filtersOptions.clients = convertArray(data);
        } else {
            filtersOptions.clients = topClients.value;
        }
    }

    async function getTopEcosystemsList() {
        const {data} = await ecosystemsService.getTopListForSearch();
        topEcosystems.value = convertArray(data);
        filtersOptions.ecosystems = topClients.value;
    }

    async function getEcosystemsList(query) {
        if (query.length) {
            const {data} = await ecosystemsService.getListForSearch(query);
            filtersOptions.ecosystems = convertArray(data);
        } else {
            filtersOptions.ecosystems = topEcosystems.value;
        }
    }

    async function getTrackersList() {
        const {data} = await trackersService.getList();

        filtersOptions.trackers = data.map(item => ({
            id: item.id,
            value: item.id,
            name: item.name,
        }));

        filtersOptions.trackers.unshift({id: 'all', value: 'all', name: 'All Trackers'})
    }

    async function addLocations(locations) {
        const {data} = await locationsService.getLocationsList(locations);

        filtersOptions.locations.push(
            ...data.map(item => ({
                name: item.name,
                value: item.value
            }))
        );
    }

    function convertArray(array) {
        return array.map(item => {
            if (item.name !== undefined) {
                return {
                    value: item.name,
                    name: item.name,
                }
            } else {
                return {
                    value: item,
                    name: item,
                }
            }
        })
    }

    return {
        filtersOptions,
        methods: {
            getInvestorsList,
            getTopInvestorsList,
            getLocationsList,
            getTopLocationsList,
            getTagsList,
            getTopClientsList,
            getClientsList,
            getTopEcosystemsList,
            getEcosystemsList,
            getTrackersList,
            addLocations
        }
    }
}
