import {ref} from "vue";
import searchService from "@/services/search.service";
import {updateFilterRule} from "@/helpers/equation";

export default function () {
    const suggestedAiParams = ref(null);
    const aiLoading = ref(false);
    const aiFailed = ref(false);

    async function getQueryParams(aiText, aiModel) {
        suggestedAiParams.value = null;
        aiFailed.value = false;

        if (aiText.length) {
            aiLoading.value = true;

            try {
                const {data} = await searchService.getAIQueryParams(aiText, aiModel);
                suggestedAiParams.value = data;
                suggestedAiParams.value.text = aiText;

                if (!Object.keys(suggestedAiParams.value.keywords).length) {
                    aiFailed.value = true;
                }
            } finally {
                aiLoading.value = false;
            }
        }
    }

    async function applyQueryParams(type, query, promptQuery, filters, setFilter) {
        let params = suggestedAiParams.value;

        query.rules = [];
        promptQuery.rules = [];
        let id = 0;

        promptQuery.params = {
            text: params.text,
            rewritten_query: params.rewritten_query,
            exclusions: params.exclusions,
        };

        if (params.categories.length) {
            params.categories.forEach(category => {
                setFilter('categories', [category]);
                updateFilterRule(query, 'categories', filters.categories);
            })

            id += params.categories.length;
        }

        if (params.locations.length) {
            await setFilter('locations', params.locations.map(item => item.value));
            updateFilterRule(query, 'locations', filters.locations);
            ++id;
        }

        let filterTypes = {
            fundingSeries: 'funding_types',
            fundingRange: 'funding_ranges',
            revenueRange: 'revenue_ranges',
            lastFundingDate: 'last_funding_date',
            employeeCount: 'employees_count',
        }

        for (let filterType in filterTypes) {
            let filterValues = params[filterTypes[filterType]];

            if (filterValues.length) {
                setFilter(filterType, filterValues);
                updateFilterRule(query, filterType, filters[filterType]);
                ++id;
            }
        }

        let rules = [];

        for (let mainWord in params.keywords) {
            ++id;

            rules.push({
                "id": id,
                "label": "Keyword",
                "name": "keyword",
                "value": mainWord,
                "valueAlias": mainWord,
                "negative": false,
                "suggestedGroupWords": params.keywords[mainWord],
                "groupWords": params.keywords[mainWord],
                "matchCount": 1,
                "broadMode": false,
            })
        }

        if (type === 'keywords') {
            query.rules.push({
                'condition': 'OR',
                'rules': rules
            })
        } else {
            promptQuery.rules = rules;

            params.exclusions.forEach(exclusion => {
                ++id;

                promptQuery.rules.push({
                    "id": id,
                    "label": "Keyword",
                    "name": "keyword",
                    "value": exclusion,
                    "valueAlias": exclusion,
                    "negative": true,
                    "groupWords": [],
                    "matchCount": 1,
                    "broadMode": false,
                });
            });
        }

        //this.$bvModal.hide('ai-text-modal');
        //this.currentPage = 1;
    }

    return {
        suggestedAiParams,
        aiLoading,
        aiFailed,
        getQueryParams,
        applyQueryParams,
    }
}
