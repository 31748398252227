import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/common/Home.vue'
import store from '@/store'

import common from "@/router/common";
import opportunities from '@/router/opportunities';
import registration from "@/router/registration";
import trackers from "@/router/trackers";
import startups from "@/router/startups";
import ecosystems from "@/router/ecosystems";
import ecosystem from "@/router/ecosystem";
import user from "@/router/user";
import team from "@/router/team";
import settings from "@/router/settings";
import adminSettings from "@/router/admin-settings";
import agencyClients from "@/router/agency-clients";
import activity from "@/router/activity";
import files from "@/router/files";
import userService from '@/services/user.service';
import enterprises from "@/router/enterprises";

import NotFound from '@/components/common/PageNotFound';
import insights from "@/router/insights";
import resources from "@/router/resources";
import StartupsRef from "@/views/search/StartupsRef.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home,
        meta: {
            title: 'Home'
        }
    },
    {
        path: '/search',
        component: StartupsRef,
        meta: {
            title: 'Home'
        }
    },
    ...common,
    ...opportunities,
    ...registration,
    ...startups,
    ...ecosystems,
    ...ecosystem,
    ...trackers,
    ...user,
    ...team,
    ...settings,
    ...adminSettings,
    ...activity,
    ...files,
    ...enterprises,
    ...insights,
    ...resources,
    ...agencyClients,
    {
        path: '*',
        name: 'Not Found',
        meta: {
            title: 'Not Found'
        },
        component: NotFound
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.guest)) {

        if (store.getters.isLoggedIn && typeof to.meta !== "undefined" && typeof to.meta.onlyGuest !== "undefined") {
            if (typeof to.query.redirectTo !== 'undefined' && to.query.redirectTo !== '/') {
                next('/' + to.query.redirectTo)
            } else {
                next('/')
            }
        } else {
            next()
        }
    } else {
        if (store.getters.isLoggedIn) {
            let notFound = false

            if ('meta' in to && 'access' in to.meta && !to.meta.access.includes(store.state.companyType) && !(to.meta.access.includes('premiumEcosystem') && store.getters.isPremiumEcosystem)) {
                notFound = true
            } else if (typeof to.meta !== "undefined" && typeof to.meta.onlyAdmin !== "undefined" && !store.getters.isAdmin) {
                notFound = true
            } else if (typeof to.meta !== "undefined" && typeof to.meta.accountAccess !== "undefined" && !to.meta.accountAccess.includes(store.state.accountType)) {
                notFound = true
            } else if (store.state.companyType === 'ecosystem' && !store.getters.isApproved && ['/', '/settings/account'].indexOf(to.path) < 0) {
                notFound = true
                userService.getCurrentUserData()
                    .then(response => {
                        store.commit('setUser', response.data)
                        if (store.getters.isApproved) {
                            store.commit('setPageNotFound', false)
                            router.go()
                        }
                    })
            } else if (store.state.companyType === 'ecosystem' && store.state.accountType === 'invited' && to.path === '/enterprises') {
                notFound = true
            }

            if (notFound) {
                store.commit('setPageNotFound', true)
            } else {
                store.commit('setPageNotFound', false)
                next()
            }

            return
        }

        store.commit('setReturnUrl', to.fullPath)
        next('/login')
    }
})

router.afterEach((to) => {
    if (typeof to.meta !== "undefined" && to.meta.title) {
        document.title = to.meta.title + ' | SwitchPitch'
    } else {
        document.title = 'SwitchPitch'
    }
})

export default router
