<script setup>
import {defineProps, ref} from 'vue'
import AddTracker from "@/components/trackers/EditTracker.vue";
import trackersCompaniesService from "@/services/trackers-companies.service";
import startupsService from "@/services/startups.service";
import {useStore} from "vue2-helpers/vuex";
import SimilarStartups from "@/components/startups/SimilarStartups.vue";
import StartChatPopup from "@/views/messages/StartChatPopup.vue";

const props = defineProps(['items']);
const store = useStore();

const addTrackerModal = ref(null);
const addStartupToTracker = ref(null);
const similarStartupsModal = ref(null);
const startChatModal = ref(null);

function action(params) {
    if (params.action === 'add-tracker') {
        addStartupToTracker.value = params.rowId;
        addTrackerModal.value.open(0, true);
    } else if (params.action === 'get-similar') {
        similarStartupsModal.value.open(params.rowId)
    } else if (params.action === 'send-message') {
        startChatModal.value.open(params.rowId);
    }
}

async function trackerCreated(trackerId) {
    store.dispatch('fetchTrackers', true);

    if (addStartupToTracker.value) {
        await trackersCompaniesService.addCompanies([trackerId], [addStartupToTracker.value]);
        const {data} = await startupsService.getTrackers(addStartupToTracker.value);
        let index = props.items.findIndex(item => item.id === addStartupToTracker.value);
        props.items[index].name.trackersCount = data.length;
        addStartupToTracker.value = null
    }
}

defineExpose({
    action
});

</script>

<template>
    <div>
        <AddTracker
            v-if="store.getters.isNotEcosystemOrPremium"
            ref="addTrackerModal"
            @trackerSaved="trackerCreated"
        />

        <SimilarStartups
            type="search"
            ref="similarStartupsModal"
        />

        <StartChatPopup
            ref="startChatModal"
            contactsType="ecosystem"
        />
    </div>
</template>

<style scoped>

</style>
