<script setup>
import {constants} from "@/views/search/searchConstants";
import RadarSettings from "@/components/radar/RadarSettings.vue";
import {ref, computed, watch} from "vue";
import Multiselect from "vue-multiselect";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import SearchMultiselect from "@/components/ui/SearchMultiselect.vue";
import CustomDataFilterModal from "@/components/search/CustomDataFilterModal.vue";
import {useStore} from "vue2-helpers/vuex";

const props = defineProps(['customView','searchType','isRadarView','asideTabIndex','savedSearch',
    'savedSearches','filters','filtersOptions','customDataFilter','searchParams','query']);

const emit = defineEmits(['update:asideTabIndex','update:savedSearch','getTopInvestorsList','manageSavedSearches',
    'getInvestorsList','updateStartupLocationsList','updateClientsList','updateEcosystemsList','updateCustomDataFilter']);

const store = useStore();
const initYearsRange = [new Date(Date.now()-1000*60*60*24*365*20), new Date(Date.now()-1000*60*60*24*365*10)];
const showLastFundingDateFilter = ref(false);
const showYearFoundedFilter = ref(false);
const showLastInteractionDateFilter = ref(false);
const irlScoreFilter = ref([1, 10]);
const customDataFilterModal = ref(null);
const radarSettings = ref(null);

// Computed
// -------------------------------------------------------------------------------------------------
const radarSettingsTabClass = computed(() => !props.isRadarView ? 'd-none' : '');

const asideTabIndex = computed({
    get: () => props.asideTabIndex,
    set: (value) => {
        if (value === 1) {
            let rule = props.query.rules.find(rule => rule.name === 'clients');

            if (rule) {
                radarSettings.value.setCategory('client', rule.value);
            }
        }

        emit('update:asideTabIndex', value);
    }
});

const savedSearch = computed({
    get: () => props.savedSearch,
    set: (value) => emit('update:savedSearch', value),
});

const fundingFiltersSet = computed(() => {
    return props.filters.fundingSeries.length || props.filters.fundingRange.length || props.filters.investors.length || props.filters.lastFundingDate?.length;
});

const irlScoreFiltersSet = computed(() => props.filters.irlScore[0] !== 1 || props.filters.irlScore[1] !== 10);

const filterByCustomFieldsLabel = computed(() =>
    props.customDataFilter.length
    ? props.customDataFilter.length + (props.customDataFilter.length === 1 ?  " custom field selected" : " custom fields selected")
    : "Filter By Your Custom Fields"
);

const filtersOptionsEmployeeCount = computed(() =>
    props.searchType === constants.SEARCH_TYPE_ENTERPRISES
    ? props.filtersOptions.employeeCount.concat({name: 'More than 500', value: {'min': 501}})
    : props.filtersOptions.employeeCount
);

const moreFiltersSet = computed(() => 
    props.filters.revenueRange.length ||
    props.filters.employeeCount.length ||
    props.filters.foundedYear?.length ||
    props.searchParams.hasInteractions ||
    props.searchParams.notOnCrunchbase ||
    props.searchParams.includeInactive !== !!store.getters.settings.search_inactive_companies ||
    props.searchParams.includeAcquired !== !!store.getters.settings.search_acquired_companies
);

watch(() => props.filters.lastFundingDate, (value) => {
    showLastFundingDateFilter.value = !!value;
});

watch(() => props.filters.foundedYear, (value) => {
    showYearFoundedFilter.value = !!value;
});

watch(() => props.filters.lastInteraction, (value) => {
    showLastInteractionDateFilter.value = !!value;
});

watch(() => props.filters.irlScore, (value) => {
    irlScoreFilter.value = value;
});

function openCustomDataFilterModal() {
    customDataFilterModal.value.open();
}

function irlScoreFilterChanged() {
    props.filters.irlScore = irlScoreFilter.value;
}

function trackerOptionSelected(selectedOption) {
    let newValue = [...props.filters.trackers];

    if (selectedOption.value === 'all') {
        newValue = [selectedOption];
    } else {
        if (props.filters.trackers.length === 1 && props.filters.trackers[0].value === 'all') {
            newValue = []
        }

        newValue.push(selectedOption);
    }

    console.log(newValue)

    props.filters.trackers = newValue;
}

function manageSavedSearches() {
    emit('manageSavedSearches');
}

function getTopInvestorsList() {
    emit('getTopInvestorsList');
}

function getInvestorsList(search) {
    emit('getInvestorsList', search);
}

function updateStartupLocationsList(search) {
    emit('updateStartupLocationsList', search);
}
function updateClientsList(search) {
    emit('updateClientsList', search);
}

function updateEcosystemsList(search) {
    emit('updateEcosystemsList', search);
}

function updateCustomDataFilter(filters) {
    emit('updateCustomDataFilter', filters);
}

</script>

<template>
    <aside v-if="!customView" class="aside">
        <div class="aside__inner">
            <b-tabs class="tabs-primary" :class="{'is-radar-view': isRadarView}" content-class="mt-3" v-model="asideTabIndex">
                <b-tab class="text-left">
                    <template #title>
                        Filters
                    </template>

                    <div class="tabs-primary__inner">
                        <ul class="filters__list">
                            <template v-if="searchType === constants.SEARCH_TYPE_ECOSYSTEMS">
                                <li :class="{'filters-set': filters.ecosystemTypes.length}">
                                    <a class="filters__list__title" v-b-toggle.filter-ecosystemTypes>
                                        Type
                                    </a>

                                    <b-collapse class="filters__list__content" id="filter-ecosystemTypes">
                                        <ul class="filters__list__choose mb-3">
                                            <li
                                                v-for="option in filtersOptions.ecosystemTypes"
                                                :key="option.id"
                                                class="input-checkbox mr-1"
                                            >
                                                <input
                                                    v-model="filters.ecosystemTypes"
                                                    :value="option"
                                                    :id="'filter-ecosystemTypes-' + option.value"
                                                    type="checkbox"
                                                >

                                                <label :for="'filter-ecosystemTypes-' + option.value">{{ option.name }}</label>
                                            </li>
                                        </ul>
                                    </b-collapse>
                                </li>

                                <li :class="{'filters-set': searchParams.favorited}">
                                    <a class="filters__list__title" v-b-toggle.filter-favorite>
                                        Favorite
                                    </a>

                                    <b-collapse class="filters__list__content" id="filter-favorite">
                                        <div class="input-checkbox">
                                            <input type="checkbox" id="favorited-input" v-model="searchParams.favorited">
                                            <label for="favorited-input">Favorited</label>
                                        </div>
                                    </b-collapse>
                                </li>
                            </template>

                            <li :class="{'filters-set': filters.categories.length}">
                                <a class="filters__list__title" v-b-toggle.filter-category>
                                    Category
                                </a>

                                <b-collapse class="filters__list__content" id="filter-category">
                                    <SearchMultiselect
                                        v-model="filters.categories"
                                        placeholder="Select categories"
                                        :options="filtersOptions.categories"
                                    />
                                </b-collapse>
                            </li>

                            <li
                                v-if="searchType === constants.SEARCH_TYPE_STARTUPS"
                                :class="{'filters-set': fundingFiltersSet}"
                            >
                                <a class="filters__list__title" v-b-toggle.filter-fundingSeries @click="getTopInvestorsList">
                                    Funding
                                </a>

                                <b-collapse class="filters__list__content" id="filter-fundingSeries">
                                    <ul class="filters__list__choose mb-3">
                                        <li class="input-checkbox mr-1" v-for="option in filtersOptions['fundingSeries']" :key="option.id">
                                            <input v-model="filters.fundingSeries" :value="option" :id="'filter-fundingSeries-' + option.value" type="checkbox">
                                            <label :for="'filter-fundingSeries-' + option.value">{{ option.name }}</label>
                                        </li>
                                    </ul>

                                    <h6 class="heading6 heading6--secondary" v-if="showLastFundingDateFilter">Last Funding Date:</h6>
                                    <a
                                        v-if="!showLastFundingDateFilter"
                                        class="calendar-link"
                                        style="margin-bottom: 10px"
                                        @click="showLastFundingDateFilter = true"
                                    >
                                        Select Funding Date
                                    </a>

                                    <DatePicker
                                        v-else
                                        v-model="filters.lastFundingDate"
                                        style="margin-bottom: 10px"
                                        type="year"
                                        range
                                        :default-value="initYearsRange"
                                    />

                                    <SearchMultiselect
                                        v-model="filters.fundingRange"
                                        placeholder="Funding range"
                                        :options="filtersOptions.fundingRange"
                                        class="mb-3"
                                    />

                                    <SearchMultiselect
                                        v-model="filters.investors"
                                        placeholder="Investors"
                                        :options="filtersOptions.investors"
                                        @search-change="getInvestorsList"
                                    />
                                </b-collapse>
                            </li>

                            <li :class="{'filters-set': filters.locations.length}">
                                <a class="filters__list__title" v-b-toggle.filter-location>
                                    Location
                                </a>

                                <b-collapse class="filters__list__content" id="filter-location">
                                    <SearchMultiselect
                                        v-model="filters.locations"
                                        placeholder="Select locations"
                                        :options="filtersOptions.locations"
                                        @search-change="updateStartupLocationsList"
                                    />
                                </b-collapse>
                            </li>

                            <li :class="{'filters-set': irlScoreFiltersSet}" v-if="searchType === constants.SEARCH_TYPE_STARTUPS">
                                <a class="filters__list__title" v-b-toggle.filter-score>
                                    IRL Score
                                </a>

                                <b-collapse class="filters__list__content" id="filter-score">
                                    <header class="filter-score__header">
                                        {{irlScoreFilter[0]}} - {{irlScoreFilter[1]}}
                                    </header>

                                    <VueSlider
                                        ref="scoreSlider"
                                        v-model="irlScoreFilter"
                                        tooltip="none"
                                        :min="1"
                                        :max="10"
                                        @drag-end="irlScoreFilterChanged"
                                    />
                                </b-collapse>
                            </li>

                            <li
                                v-if="searchType === constants.SEARCH_TYPE_STARTUPS && store.getters.tagsLoaded && (filtersOptions.tags?.length || store.state.user.permissions.includes('tags-manage'))"
                                :class="{'filters-set': filters.tags.length}"
                            >
                                <a class="filters__list__title" v-b-toggle.filter-tags>
                                    Tags
                                </a>

                                <b-collapse class="filters__list__content" id="filter-tags">
                                    <SearchMultiselect
                                        v-if="filtersOptions.tags?.length"
                                        v-model="filters.tags"
                                        placeholder="Select tags"
                                        :options="filtersOptions.tags"
                                    />

                                    <router-link v-else-if="store.state.user.permissions.includes('tags-manage')" to="/settings/my-tags" class="filters__list__link">Create Tag</router-link>
                                </b-collapse>
                            </li>

                            <li
                                v-if="searchType === constants.SEARCH_TYPE_STARTUPS"
                                :class="{'filters-set': !!customDataFilter.length}"
                            >
                                <a class="filters__list__title" v-b-toggle.filter-custom-fields>
                                    Custom Fields
                                </a>

                                <b-collapse class="filters__list__content" id="filter-custom-fields">
                                    <a
                                        class="filters__list__link"
                                        :class="customDataFilter.length ? 'filters__custom_selected' : 'filters__custom'"
                                        @click="openCustomDataFilterModal">{{ filterByCustomFieldsLabel }}
                                    </a>
                                </b-collapse>

                                <CustomDataFilterModal
                                    :for-search="true"
                                    :init-filters="customDataFilter"
                                    ref="customDataFilterModal"
                                    @updateCustomDataFilter="updateCustomDataFilter"
                                />
                            </li>

                            <li
                                v-if="searchType === constants.SEARCH_TYPE_STARTUPS"
                                :class="{'filters-set': filters.clients.length || filters.clientsCategories.length}"
                            >
                                <a class="filters__list__title" v-b-toggle.filter-clients>
                                    <span class="filters__list__title__mid">Competitive Intelligence</span>
                                </a>

                                <b-collapse class="filters__list__content" id="filter-clients">
                                    <SearchMultiselect
                                        v-model="filters.clients"
                                        placeholder="Select clients"
                                        :options="filtersOptions.clients"
                                        class="mb-3"
                                        @search-change="updateClientsList"
                                    />

                                    <SearchMultiselect
                                        v-model="filters.clientsCategories"
                                        placeholder="Select industries"
                                        :options="filtersOptions.categories"
                                        @search-change="updateStartupLocationsList"
                                    />
                                </b-collapse>
                            </li>

                            <li
                                v-if="searchType === constants.SEARCH_TYPE_STARTUPS && store.getters.isEnterprise"
                                :class="{'filters-set': filters.ecosystems.length}"
                            >
                                <a class="filters__list__title" v-b-toggle.filter-ecosystem>
                                    Ecosystems
                                </a>

                                <b-collapse class="filters__list__content" id="filter-ecosystem">
                                    <SearchMultiselect
                                        :disabled="store.getters.isFreeEnterprise"
                                        v-model="filters.ecosystems"
                                        placeholder="Select ecosystems"
                                        :options="filtersOptions.ecosystems"
                                        @search-change="updateEcosystemsList"
                                    />
                                </b-collapse>
                            </li>

                            <li
                                v-if="searchType === constants.SEARCH_TYPE_STARTUPS && store.getters.isEnterprise"
                                :class="{'filters-set': filters.trackers.length}"
                            >
                                <a class="filters__list__title" v-b-toggle.filter-trackers>
                                    Trackers
                                </a>

                                <b-collapse class="filters__list__content" id="filter-trackers">
                                    <SearchMultiselect
                                        v-model="filters.trackers"
                                        placeholder="Select trackers"
                                        :options="filtersOptions.trackers"
                                        @select="trackerOptionSelected"
                                    />
                                </b-collapse>
                            </li>

                            <li>
                                <a class="filters__list__title" v-b-toggle.filter-saved-searches>
                                    Saved Searches
                                </a>

                                <b-collapse class="filters__list__content" id="filter-saved-searches">
                                    <Multiselect
                                        v-model="savedSearch"
                                        :options="savedSearches"
                                        placeholder="Select saved search"
                                        select-label=""
                                        deselect-label=""
                                        group-values="items"
                                        group-label="group"
                                        label="name"
                                        track-by="name"
                                        class="multiselect-mid pl-2"
                                    />

                                    <a v-if="savedSearches.length" @click="manageSavedSearches" class="filters__list__link d-block text-right">Manage Saved Searches</a>
                                </b-collapse>
                            </li>

                            <li
                                v-if="store.getters.isEnterprise && searchType === constants.SEARCH_TYPE_STARTUPS"
                                :class="{'filters-set': filters.similarTracker}"
                            >
                                <a class="filters__list__title" v-b-toggle.filter-similar-tracker>
                                    <span class="filters__list__title__wide">Search By Similar Startups</span>
                                </a>

                                <b-collapse class="filters__list__content" id="filter-similar-tracker">
                                    <Multiselect
                                        v-model="filters.similarTracker"
                                        placeholder="Select tracker"
                                        :options="filtersOptions.trackers"
                                        select-label=""
                                        deselect-label=""
                                        label="name"
                                        track-by="name"
                                        class="multiselect-mid pl-2"
                                    />
                                </b-collapse>
                            </li>

                            <li :class="{'filters-set': moreFiltersSet}">
                                <a class="filters__list__title" v-b-toggle.filter-more>
                                    More
                                </a>

                                <b-collapse class="filters__list__content" id="filter-more">
                                    <template v-if="searchType === constants.SEARCH_TYPE_STARTUPS">
                                        <SearchMultiselect
                                            v-model="filters.revenueRange"
                                            placeholder="Revenue range"
                                            :options="filtersOptions.revenueRange"
                                            class="mb-3"
                                        />
                                    </template>

                                    <SearchMultiselect
                                        v-model="filters.employeeCount"
                                        placeholder="Employee count"
                                        :options="filtersOptionsEmployeeCount"
                                        class="mb-3"
                                    />

                                    <template v-if="searchType !== constants.SEARCH_TYPE_ENTERPRISES">
                                        <h6 class="heading6 heading6--secondary">Date founded:</h6>
                                        <a
                                            v-if="!showYearFoundedFilter"
                                            class="calendar-link"
                                            style="margin-bottom: 10px"
                                            @click="showYearFoundedFilter = true"
                                        >
                                            Select Date
                                        </a>

                                        <DatePicker
                                            v-else
                                            v-model="filters.foundedYear"
                                            type="year"
                                            range
                                            :default-value="initYearsRange"
                                        />
                                    </template>

                                    <template v-if="searchType === constants.SEARCH_TYPE_STARTUPS">
                                        <div class="input-checkbox mt-3">
                                            <input type="checkbox" id="include-input" v-model="searchParams.hasInteractions">
                                            <label for="include-input">Has Interactions</label>
                                        </div>

                                        <template v-if="searchParams.hasInteractions">
                                            <a
                                                v-if="!showLastInteractionDateFilter"
                                                class="calendar-link"
                                                @click="showLastInteractionDateFilter = true"
                                            >
                                                Select Date
                                            </a>

                                            <DatePicker
                                                v-else
                                                v-model="filters.lastInteraction"
                                                range
                                                format = "MM/DD/YYYY"
                                            />
                                        </template>

                                        <div class="input-checkbox mt-3">
                                            <input type="checkbox" id="include-inactive-input" v-model="searchParams.includeInactive">
                                            <label for="include-inactive-input">Show Inactive</label>
                                        </div>

                                        <div class="input-checkbox mt-3">
                                            <input type="checkbox" id="include-acquired-input" v-model="searchParams.includeAcquired">
                                            <label for="include-acquired-input">Show Acquired</label>
                                        </div>

                                        <div class="input-checkbox mt-3">
                                            <input type="checkbox" id="not-on-crunchbase" v-model="searchParams.notOnCrunchbase">
                                            <label for="not-on-crunchbase">Not on Crunchbase</label>
                                        </div>
                                    </template>
                                </b-collapse>
                            </li>
                        </ul>
                    </div>
                </b-tab>

                <b-tab v-if="searchType === constants.SEARCH_TYPE_STARTUPS" :title-item-class="radarSettingsTabClass">
                    <template #title>
                        Radar Settings
                    </template>

                    <div class="tabs-primary__inner">
                        <RadarSettings ref="radarSettings"/>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </aside>
</template>

<style scoped>

</style>
