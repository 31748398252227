<template>
    <div>
        <Header/>
        <search v-if="hasAccess" search-type="startups"/>
    </div>
</template>

<script>
import Search from "@/views/search/Search";
import {canSearchStartups} from "@/helpers/functions";
import Header from "@/components/startups/Header";

export default {
    components: {
        Header,
        Search,
    },

    mounted() {
        this.$store.watch(
            (state) => {
            this.checkAccess(state)
        });
    },

    computed: {
        hasAccess() {
            return canSearchStartups(this.$store.state);
        }
    },

    methods: {
        checkAccess(state) {
            if ('account_type' in state.user && !canSearchStartups(state)) {
                this.$store.commit('setPageNotFound', true);
            }
        }
    }
}
</script>
