import {reactive, ref, watch} from "vue";

export default function (getListMethod) {
    const gridParams = reactive({
        columns: [],
        currentPage: 1,
        pageLength: 25,
        totalRowsCount: 0,
        filters: {},
        sort: {
            field: '',
            dir: '',
        },
        selectedRows: [],
        selectedAllResults: false,
    })

    const items = ref([]);
    
    watch(() => gridParams.pageLength, () => {
        let lastPage = Math.ceil(gridParams.totalRowsCount / gridParams.pageLength)

        if (gridParams.currentPage > lastPage) {
            gridParams.currentPage = lastPage
        }

        getListMethod();
    });

    function getCommonRequestParams(includeFilters = true) {
        let params = {
            columns: gridParams.columns.map(column => column.name),
            sort: gridParams.sort,
            currentPage: gridParams.currentPage,
            pageLength: gridParams.pageLength,
        };

        if (includeFilters) {
            params.filters = gridParams.filters;
        }

        return params;
    }

    async function getList() {
        gridParams.loading = true;
        gridParams.items = [];
        gridParams.totalRowsCount = 0;

        try {
            let {data} = await getListMethod();

            gridParams.items = data.data;
            gridParams.totalRowsCount = parseInt(data.total_count);
            gridParams.currentPage = data.current_page;
        } finally {
            gridParams.loading = false;
        }
    }

    function updateList() {
        getListMethod()
    }

    function changePage(page) {
        gridParams.currentPage = page
        updateList()
    }

    function changeSort(sort) {
        gridParams.sort = sort
        updateList()
    }

    function changeSelectedRows(ids) {
        gridParams.selectedRows = ids
    }

    function selectAllResults(value) {
        gridParams.selectedAllResults = value
    }

    const gridMethods = {
        getCommonRequestParams,
        getList,
        changePage,
        changeSort,
        changeSelectedRows,
        selectAllResults,
    }

    return {
        items,
        gridParams,
        gridMethods
    }
}
