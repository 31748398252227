<template>
    <div>
        <div class="topbar">
            <enterprises-header></enterprises-header>
        </div>

        <search
            ref="search"
            search-type="enterprises"
            v-on:invite="invite"
        >
        </search>

        <add-connection
            ref="addEnterprise"
        >
        </add-connection>

    </div>
</template>

<script>
import grid from "@/mixins/grid";
import EnterprisesHeader from "@/components/enterprises/EnterprisesHeader";
import addConnection from "@/components/connections/AddEnterpriseConnection";
import Search from "@/views/search/Search";

export default {
    mixins: [grid],

    components: {
        EnterprisesHeader,
        addConnection,
        Search,
    },

    data() {
        return {
            connections: [],

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Description',
                    name: 'description',
                },
                {
                    label: 'Category',
                    name: 'categoriesStr',
                },
                {
                    label: 'Location',
                    name: 'location',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                },
            ],

            addCellData: {
                companyType: "enterprises",
            },
        }
    },

    mounted() {
        this.$store.watch(state => {
            if (state.needUpdateEnterprises) {
                this.$refs.search.getList()
            }
        })
    },

    methods: {

        addEnterprise() {
            this.$store.commit('setAddEnterprise', true);
            this.$nextTick(() => {
                this.$store.commit('setAddEnterprise', false);
            })
        },

        invite(id) {
            this.$refs.addEnterprise.open(id)
        },
    }
}
</script>

<style scoped>

</style>
