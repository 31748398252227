import {reactive, ref, watch, nextTick} from "vue";
import {deleteRule, invertRule, getLastId, toggleCondition, updateFilterRule, normalizeQuery, getCurrentRuleLevelByField, updateRule} from "@/helpers/equation";
import {sanitizeUrl} from "@/helpers/string";
import useAiPrompt from "@/composables/search/useAiPrompt";
import useFilters from "@/composables/search/useFilters";
import {useRoute} from "vue2-helpers/vue-router";

export default function (getList) {
    const route = useRoute();
    const {filters, customDataFilter, notWatchFilters, filtersOptions,
        clearFilters, clearFilter, populateFilters, removeFilterFromGroupByValue, setFilter, filtersOptionsMethods} = useFilters();

    const {aiLoading, aiFailed, suggestedAiParams, getQueryParams: aiPromptGetQueryParams, applyQueryParams: aiPromptApplyQueryParams} = useAiPrompt();

    const query = reactive({
        rules: [],
    });

    const promptQuery = reactive({
        rules: [],
        params: {
            text: "",
            rewritten_query: "",
            exclusions: [],
        },
    });

    const searchParams = reactive({
        includeHidden: false,
        hasInteractions: false,
        includeInactive: false,
        includeAcquired: false,
        notOnCrunchbase: false,
        favorited: false,
    });

    const notWatchQuery = ref(false);
    const notWatchSearchParams = ref(false);
    const customFilterFieldsNames = ref([]);

    // Watch query
    watch([query, promptQuery, searchParams], () => {
        if (!notWatchQuery.value && !notWatchSearchParams.value) {
            notWatchQuery.value = true;
            normalizeQuery(query);
            nextTick(() => notWatchQuery.value = false)
            getList();
        }
    });

    // Watch filters
    for (let name in filters) {
        watch(() => filters[name], (newValue, oldValue) => {
            if (!notWatchFilters.value && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                updateFilterRule(query, name, newValue);
            }
        });
    }

    // Check search query
    let keywords = route.query.keywords;

    if (keywords) {
        if (!Array.isArray(keywords)) {
            keywords = [keywords]
        }

        let rules = [];
        let id = 0;

        keywords.forEach(word => {
            id++;
            let keyWord = sanitizeUrl(word);

            const rule =
                {
                    "id"         : id,
                    "label"      : "Keyword",
                    "name"       : "keyword",
                    "value"      : keyWord,
                    "valueAlias" : keyWord,
                    "negative"   : false,
                    "groupWords"   : [],
                    "matchCount" : 1,
                }

            rules.push(rule);
        })

        if (rules.length === 1) {
            query.rules = rules;
        } else {
            query.rules = [
                {
                    'condition': 'OR',
                    'rules': rules,
                }
            ];
        }
    }

    let ecosystemName = route.query.ecosystem

    if (ecosystemName && !Array.isArray(ecosystemName)) {
        let name = decodeURIComponent(ecosystemName);

        filters.ecosystems = [
            ...filters.ecosystems,
            {
                name: name,
                value: name,
            }
        ];
    }

    function addKeyword(value) {
        if (value.length) {
            let keyWord = value

            if (keyWord.indexOf(':') < 0) {
                keyWord = sanitizeUrl(keyWord);
            }

            let rule = {
                "id"         : parseInt(getLastId(query)) + 1,
                "label"      : "Keyword",
                "name"       : "keyword",
                "value"      : keyWord,
                "valueAlias" : keyWord,
                "negative"   : false,
                "groupWords"   : [],
                "matchCount" : 1,
            }

            query.rules.push(rule);
        }
    }

    function deleteRuleLocal(rule, isPromptQuery = false, field = 'id') {
        deleteRule(isPromptQuery ? promptQuery : query, rule, field);

        if (['categories','tags'].includes(rule.name)) {
            removeFilterFromGroupByValue(rule.name, rule.value[0])
        } else if (rule.name !== 'keywords') {
            clearFilter(rule.name)
        }
    }

    function invertRuleLocal(rule, isPromptQuery = false) {
        invertRule(isPromptQuery ? promptQuery : query, rule);
    }

    function deleteAllRules() {
        query.rules = [];
        clearPrompt();
        clearFilters();
    }

    function clearPrompt() {
        promptQuery.rules = [];
        promptQuery.params.text = '';
        promptQuery.params.rewritten_query = '';
        promptQuery.params.exclusions = [];
    }

    function toggleConditionLocal(rule, inGroup, oldCond) {
        toggleCondition(query, rule, inGroup, oldCond);
    }

    function updateCustomDataFilter(filters) {
        customDataFilter.value = filters;

        for (let i in filters) {
            let filter = filters[i]
            let nextId = getLastId(query) + 1;

            let rule = {
                "id": nextId,
                "name": filter.name,
                "label": filter.label,
                "negative": false,
                "value": filter.value,
                "valueAlias": filter.valueAlias,
            }

            let level = getCurrentRuleLevelByField(query, rule)

            if (typeof level.level_1 === "undefined") {
                query.rules.push(rule);
            } else {
                updateRule(query, rule, level)
            }
        }

        customFilterFieldsNames.value.forEach(item => {
            if (!filters.some(filter => filter.name === item)) {
                let rule = {
                    "name": name
                }

                deleteRule(query, rule, 'name');
            }
        });

        customFilterFieldsNames.value = filters.map(item => item.name);
    }

    async function getAIQueryParams(aiText, model, apply = false) {
        await aiPromptGetQueryParams(aiText, model);

        if (!aiFailed.value && apply) {
            await applyAiQueryParams('query');
        }
    }

    async function applyAiQueryParams(type) {
        notWatchQuery.value = true;
        notWatchFilters.value = true;

        await aiPromptApplyQueryParams(type, query, promptQuery, filters, setFilter);
        getList();

        nextTick(() => {
            notWatchQuery.value = false;
            notWatchFilters.value = false;
        });
    }

    return {
        filters,
        query,
        promptQuery,
        searchParams,
        customDataFilter,
        notWatchSearchParams,
        aiLoading,
        aiFailed,
        filtersOptions,
        suggestedAiParams,

        addKeyword,
        updateCustomDataFilter,
        deleteRule: deleteRuleLocal,
        invertRule: invertRuleLocal,
        toggleCondition: toggleConditionLocal,
        deleteAllRules,
        getAIQueryParams,
        applyAiQueryParams,

        populateFilters,
        filtersOptionsMethods,
    }
}
