<script setup>
import RadarChart from "@/components/radar/RadarChart.vue";
import StartupDetailsPopover from "@/components/radar/StartupDetailsPopover.vue";
import {ref, defineProps, onMounted, computed, watch} from "vue";
import { useStore } from 'vue2-helpers/vuex';

const props = defineProps(['query','promptQuery','searchParams']);
const store = useStore();

const settings = ref(null);
const startupDetailsPopover = ref(null);
const customSettings = computed(() => store.getters['radar/customRadarSettings']);
const defaultSettings = computed(() => store.getters['radar/defaultSettings']);
const chartData = computed(() => store.getters['radar/radarInfo']);

watch([props.query, props.promptQuery, props.searchParams], () => {
    getChartData();
});

watch(customSettings, (newSettings) => {
    processChangedSettings(newSettings);
});

onMounted(() => {
    if (Object.keys(customSettings.value).length) {
        processChangedSettings(customSettings.value);
    } else {
        getChartData();
    }
});

function getChartData() {
    const postData = {
        query: {
            main: props.query,
            prompt: props.promptQuery,
        },

        settings: settings.value || defaultSettings.value,
        params: props.searchParams,
    };

    store.dispatch('radar/fetchRadarInfo', postData);
}

function processChangedSettings(customSettings) {
    settings.value = customSettings;
    getChartData();
}

function startupLogoClick(startupId) {
    startupDetailsPopover.value.hidePopover()
    startupDetailsPopover.value.openPopover(startupId)
}

</script>

<template>
    <div id="radar-wrapper">
        <RadarChart
            ref="radarChart"
            :chartData="chartData"
            @startupLogoClick="startupLogoClick"
        />

        <StartupDetailsPopover
            ref="startupDetailsPopover"
            target="radar-container"
        />
    </div>
</template>

<style scoped>

</style>
