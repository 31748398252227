export default function (resolvers, callback) {
    const promises = [];

    for (let i in resolvers) {
        promises.push(new Promise((resolve) => {
            resolvers[i] = resolve;
        }));
    }

    Promise.all(promises).then(callback);
}
